import React, { useState } from "react";

// Website Images
import Web1 from "../images/Website/web1.webp";
import Web2 from "../images/Website/web2.webp";
import Web3 from "../images/Website/web3.png";
import Web4 from "../images/Website/web4.webp";
import Web5 from "../images/Website/web5.webp";
import Web6 from "../images/Website/web6.webp";
// Logo Images
// import logo21 from "../images/Logo/logo21.jpeg";
// import logo22 from "../images/Logo/logo22.jpeg";
// import logo23 from "../images/Logo/logo23.jpeg";
// import logo24 from "../images/Logo/logo24.png";
// import logo25 from "../images/Logo/logo25.png";
// import logo26 from "../images/Logo/logo26.jpeg";
// import logo27 from "../images/Logo/logo27.png";
// import logo28 from "../images/Logo/logo28.png";
// import logo29 from "../images/Logo/logo29.jpeg";
// import logo30 from "../images/Logo/logo30.jpeg";
// import logo31 from "../images/Logo/logo31.jpeg";
// import logo32 from "../images/Logo/logo32.png";
// import logo33 from "../images/Logo/logo33.jpeg";
import logo34 from "../images/Logo/logo34.png";
import logo35 from "../images/Logo/logo35.png";
import logo36 from "../images/Logo/logo36.png";
import logo37 from "../images/Logo/logo37.png";
import logo38 from "../images/Logo/logo38.png";
import logo1 from "../images/Logo/logo1.webp";
import massagetherapy from "../images/massagetherapy.jpg";
import logo5 from "../images/Logo/logo5.webp";
import logo8 from "../images/Logo/logo8.jpeg";
import logo11 from "../images/Logo/logo11.jpeg";
import logo12 from "../images/Logo/logo12.webp";
import logo13 from "../images/Logo/logo13.webp";
import logo16 from "../images/Logo/logo16.webp";
import logo19 from "../images/Logo/logo19.webp";
import logo20 from "../images/Logo/logo20.webp";

// DMM Images
import DMM1 from "../images/DMM/N_DM_1.jpeg";
import DMM2 from "../images/DMM/N_DM_2.jpeg";
import DMM3 from "../images/DMM/N_DM_3.jpeg";
import DMM4 from "../images/DMM/N_DM_4.jpeg";
import DMM5 from "../images/DMM/N_DMM_5.jpeg";
import DMM6 from "../images/DMM/N_DMM_6.jpeg";
import DMM7 from "../images/DMM/DMMPortfolio-7.jpg";
import DMM8 from "../images/DMM/DMMPortfolio-8.jpg";

// UI/UX Images
// import Design1 from "../images/ui-1.jpg"
// import Design2 from "../images/ui-2.jpg"
// import Design3 from "../images/ui-3.jpg"
// import Design4 from "../images/ui-4.jpg"
// import Design5 from "../images/ui-5.png"
// import Design6 from "../images/ui-6.jpg"
// import Design7 from "../images/ui-7.jpg"
// import Design8 from "../images/ui-8.jpg"
// import Design9 from "../images/ui-9.jpg"
// import Design10 from "../images/ui-10.jpg"
// import Design11 from "../images/ui-11.png"

const PortfolioImages = () => {
  const [category, setCategory] = useState("DMM");

  const handleCategoryChange = (category) => {
    setCategory(category);
  };

  return (
    <>
      <div className="md:flex">
        <h1 className="text-[6vw] md:text-[3vw] pl-3 font-titleFont font-extrabold">
          Our Awesome Work Portfolio
        </h1>
        <div className="block sm:block md:flex lg:flex m-auto pb-4 pt-4 font-bold font-titleFont">
          <button
            onClick={() => handleCategoryChange("website")}
            className=" rounded-full px-4 mb-1  sm:pl-3 sm:pr-3 md:pl-6 md:pr-6 lg:px-2  hover:text-[#5c5cc0] active:text-[#5454D4]"
          >
            WEB Development
          </button>
        </div>
      </div>

      <div className="block sm:block md:grid lg:grid grid-cols-3 justify-between pt-6 px-6 mt-5">
      
        {category === "website" && (
          <>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web1}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web2}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web3}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web4}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web5}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={Web6}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
          </>
        )}
      
        {category === "DMM" && (
          <>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM7}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM8}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM1}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM2}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM3}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM4}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM5}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
              <img
                src={DMM6}
                alt=""
                className="border rounded-lg w-full h-full"
              />
            </div>
          </>
        )}
        {/* {category === "ui/ux" && (
            <>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={Design1}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM8}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM1}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM2}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM3}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM4}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM5}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>
            <div className="relative border rounded-lg w-[90vw] h-auto  md:w-[29vw] mb-10">
                <img
                  src={DMM6}
                  alt=""
                  className="border rounded-lg w-full h-full"
                />
              </div>

              
            </>
          )} */}
      </div>
    </>
  );
};

export default PortfolioImages;
